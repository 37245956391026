/* Place your extensions here to make migration to a new release easier. */
.login-body {
    padding-top: 100px;
    box-sizing: border-box;
}

.login-body .login-panel {
    background-color: #f7f7f7;
    width: 460px;
    margin: 0 auto;
    text-align: center;
    padding: 60px 100px;
    box-sizing: border-box;
   
}

.login-body .login-panel h1 {
    font-size: 22px;
    color: #2d353c;
}

.login-body .login-panel h2 {
    font-size: 13px;
    color: #b3b3b3;
}

.login-body .login-panel img {
    width: 130px;
}

.button-auto  {
    width: 130px;
}


.login-body .login-panel label {
    color: #767b7f;
    vertical-align: middle;
    margin-left: 6px;
}

.button-demo button {
    margin-right: .5em;
  }

  #idEditBuse input {
    width: 40px;
  }

@media (max-width: 640px) {
    .login-body {
        padding-top: 40px;
    }

    .login-body .login-panel {
        width: 320px;
        padding: 30px 50px;
    }
}